import $ from 'jquery';
import Masonry from 'masonry-layout';
import { gsap } from "gsap";
import { Draggable } from 'gsap/Draggable';
import 'src/legacy/js/vendors/foundation/foundation';

gsap.registerPlugin(Draggable);

const ua = navigator.userAgent.toLowerCase();
const isAndroid = ua.indexOf("android") > -1;

const deviceTiltEvent = 'deviceorientation';
const gridElement = document.querySelector('.grid');
const gallery = document.getElementById( 'gallery-section' );
const wwBadge = $('.gallery-section__info');
const imageGrid = $('#gallery-section-image');
const initialHeight = imageGrid.height();

let xVal = null;
let yVal = null;
let isLargeTilt = false;
let isValFixed = false;
let isBadgeHidden = false;

function checkMove(x, y) {
    if(!isValFixed) {
        xVal = x;
        yVal = y;
    }
    isValFixed = true;

    isLargeTilt = (x - xVal > 10 ||  x - xVal < -10 || y - yVal > 10 || y - yVal < -10);

    if(isLargeTilt && !isBadgeHidden) {
        wwBadge.addClass('is-hide');
        isBadgeHidden = true
    }
    if (!isLargeTilt && isBadgeHidden) {
        wwBadge.removeClass('is-hide');
        isBadgeHidden = false
    }
    
}

function checkPosition () {
    if(!gallery) return;
    const posFromTop = gallery.getBoundingClientRect().top;
    if (posFromTop - window.innerHeight <= 0) {
        getPermission();
    }
}

function restyleBadgeToDraggable () {
    let draggableText = $('.gallery-section__info--mobile-tablet .gallery-section__info__text').attr('data-draggable-text');
    $('.gallery-section__info--mobile-tablet .gallery-section__info__text').text(draggableText);
    $('.gallery-section__info--mobile-tablet img').attr("src","/dist/images/range-cursor.svg").attr("data-src","/dist/images/range-cursor.svg");
}

function initDraggable () {
    try {
        Draggable.create($("#gallery-section-image"), {
            type:"x,y",
            edgeResistance:0.5,
            cursor: "move",
            width: 1000,
            bounds:"#gallery-section",
            easingFunction: 'ease-in',
            onDragStart:function() {
                wwBadge.addClass('is-hide');
            },
            onDragEnd:function() {
                wwBadge.removeClass('is-hide');
            }
        });
    } catch (err) {
        console.log(err);
    }
}

function getPermission () {
    if ( typeof( DeviceOrientationEvent ) !== "undefined" && typeof( DeviceOrientationEvent.requestPermission ) === "function" ) {
        DeviceOrientationEvent.requestPermission()
            .then( response => {
                if ( response == "granted" ) {
                    window.addEventListener(deviceTiltEvent, handleOrientation);
                    window.addEventListener( deviceTiltEvent, Foundation.utils.throttle((e) => checkMove(e.gamma, e.beta), 200));
                    window.removeEventListener('scroll', checkPosition);
                } else {
                    initDraggable();
                    restyleBadgeToDraggable();
                }
            })
            .catch(
                error => {
                    initDraggable();
                    restyleBadgeToDraggable();
                }
            )
    } else {
        window.removeEventListener('scroll', checkPosition);
    }
}

const handleOrientation = (e) => {
    if(screen.availHeight > screen.availWidth){
        onSuccess({ x: e.gamma, y: e.beta, c: e.alpha });  // portrait mode
    } else {
        onSuccess({ x: e.beta, y: e.gamma, c: e.alpha });  // landscape mode
    }
};

function onSuccess({ x, y }) {
    if (window.screen.width <= 960) {
        // Because we don't want to have the device upside down
        // We constrain the x value to the range [-90,90]
        if (x > 45) {
            x = 45;
        }
        if (x < -70) {
            x = -70;
        }
        if (y > 45) {
            y = 45;
        }
        if (y < -45) {
            y = -45;
        }
        const xPercentage = x > 0 ? (x / 90) * 100 : (x / 70) * 100;
        const yPercentage = (y / 90) * 100;
        function percentToPixel(percent, max, min) {
            return (percent / 100) * (max - min) + min;
        }
        const maxX = imageGrid.width();
        imageGrid.attr(
            'style',
            `transform: translate3d(${percentToPixel(xPercentage, maxX, 0)}px, ${percentToPixel(
                yPercentage,
                initialHeight,
                0
            )}px, 0px) scale(2) !important`
        );
    }
}

try {
    if(gridElement) {
        const msnry = new Masonry(gridElement, {
            itemSelector: '.grid-item',
            resize: true,
            fitWidth: true
        });
    }

    if ( isAndroid ) {
        window.addEventListener('scroll', checkPosition);
    } else {
        initDraggable();
        restyleBadgeToDraggable();
    }

    if ( typeof( DeviceOrientationEvent ) == "undefined" || typeof( DeviceOrientationEvent.requestPermission ) !== "function" ) {  //we don't need user permission
        if( isAndroid ) {
            window.addEventListener(deviceTiltEvent, handleOrientation);
            window.addEventListener(deviceTiltEvent, Foundation.utils.throttle((e) => checkMove(e.gamma, e.beta), 200));
        } else {
            initDraggable();
            restyleBadgeToDraggable();
        }
    }

} catch (err) {
    console.log(err);
}
