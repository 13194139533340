import $ from 'jquery';

let prevWidth = $(window).width();
let prevHeight = $(window).height();

$(window).on('load', () => {
    try {
        if (!$('.yacht-details-page').length || CSS.supports("backdrop-filter", "blur(5px)")) {
            return;
        }

        const yachtImage = '.yacht-details-page__header-page > .yacht-details-page__header-page--pic';
        const yachtImageBlur = '.yacht-details-page__header-page--blur > .yacht-details-page__header-page--pic';
        const blurContainer = '.yacht-details-page__header-page--blur';

        $(blurContainer).addClass('show-blur');
        resizeImage(yachtImage, yachtImageBlur);

        $(window).on('resize', function (e) {
            if ($(window).width() !== prevWidth) {
                resizeImage(yachtImage, yachtImageBlur);
                prevWidth = $(window).width();
            } else if($(window).height() !== prevHeight) {
                resizeImage(yachtImage, yachtImageBlur);
                prevHeight = $(window).height();
            }
        });

    } catch (e) {
        console.log('Error: ' + e);
    }
});

function resizeImage (img1, img2) {
    let sizes = {height: $(img1).height(), width: $(img1).width()};

    $(img2).height(sizes.height);
    $(img2).width(sizes.width);
}
