import $ from 'jquery';
import 'slick-carousel/slick/slick.js';
import { addInfiniteOnEdge } from 'general/js/carouselService';
import { sliderForTouchScreens } from 'general/js/carouselService';
import 'src/legacy/js/vendors/foundation/foundation';
import { initImageLightbox } from 'src/legacy/js/components/our-craft-model-full-image';
import {trackPersonalisation} from 'general/js/personalisationTracking';

const MOBILE_BREAKPOINT = 720;
const TABLET_BREAKPOINT = 1024;

let exteriorActive = true;
let prevWidth = $(window).width();
let isDragging = false;

//Add toggle effect on click
$($('.ex-in-section__heading__span')[0]).addClass('selected');
$($('.ex-in-section__heading__span')[3]).addClass('selected');
$($('.toggle-switch')[1]).addClass('active');
const toggles = document.querySelectorAll('.toggle-switch');
for (let index = 0; index < toggles.length; index++) {
    const toggle = toggles[index];
    $(toggle).click();
}
for (let index = 0; index < toggles.length; index++) {
    const toggle = toggles[index];
    toggle.addEventListener('click', toggleActiveCarousel);
}
const headingsElements = document.querySelectorAll('.ex-in-section__heading__span');
for (let index = 0; index < headingsElements.length; index++) {
    const heading = headingsElements[index];
    heading.addEventListener('click', toggleActiveCarousel);
}

const filterElements = $('.ex-in-section__filter__item');
filterElements.on('click', (e) => {
    filterElements.children().removeClass('active-opacity');
    $(e.currentTarget).children().addClass('active-opacity');
});

function toggleActiveCarousel() {
    if (exteriorActive) {
        trackPersonalisation($('.page-layout').attr('data-page-name') + ': ' + 'Interior');
        $('#exterior-carousel-wrapper').slick('slickUnfilter');
        $('#exterior-carousel-wrapper').slick('unslick');
        $('#exterior-carousel').hide();
        $('#interior-carousel').show();
        if(!$('#interior-carousel-wrapper').hasClass('slick-initialized')) initCarousel('#interior-carousel-wrapper');
        initImageLightbox('#interior-carousel-wrapper');
    } else {
        trackPersonalisation($('.page-layout').attr('data-page-name') + ': ' + 'Exterior');
        $('#interior-carousel-wrapper').slick('unslick');
        $('#interior-carousel').hide();
        $('#exterior-carousel').show();
        filterElements.children().removeClass('active-opacity');
        filterElements.first().children().addClass('active-opacity');
        if(!$('#exterior-carousel-wrapper').hasClass('slick-initialized'))initCarousel('#exterior-carousel-wrapper');
        initImageLightbox('#exterior-carousel-wrapper');
        initFilters();
    }
    exteriorActive = !exteriorActive;
}

const initFilters = () => {
    $('#exterior-carousel-wrapper .slick-slide').each((index, el) => {
        let galleryId = $($(el).children().children()).attr('data-gallery-id');
        let idArr = galleryId?.split('-');
        galleryId = idArr && idArr.length > 1 ? idArr[1] : galleryId;
        $(el).attr('data-gallery-id', `g-${galleryId}-id`);
    });
};
$('.ex-in-section__filter__item').on('click', filterClickHandler);

function filterClickHandler(event) {
    let id = $(event.target).attr('data-gallery-id')
        ? $(event.target).attr('data-gallery-id')
        : $($(event.target).parent()).attr('data-gallery-id');
    filterItems(id);
}

function filterItems(id) {
    if (id === 'all') {
        $('#exterior-carousel-wrapper').slick('slickUnfilter');
        initFilters();
        $($('#exterior-carousel-wrapper .slick-slide')[0]).addClass('active-img');
    } else {
        initFilters();
        $('#exterior-carousel-wrapper').slick('slickUnfilter');
        $('#exterior-carousel-wrapper').slick('slickFilter', function () {
            return $(this).attr('data-gallery-id') === `g-${id}-id`;
        });
        $($('#exterior-carousel-wrapper .slick-slide')[0]).addClass('active-img');
    }
    $('#exterior-carousel-wrapper').slick('slickGoTo', 0);
}

const onSlideClick = function (e) {
    if(isDragging || e.target.classList.contains('js-lightbox-trigger-image') || !$(this).hasClass('slick-slide')) return;
    let $selector = $(this).parents('.ex-in-section__carousel__active-carousel');
    if($(this).hasClass('slick-current')) {
        e.preventDefault();
        $(this).find('.js-lightbox-trigger-image').click();
    } else {
        if(!$(this).data('slickIndex')) return;
        $selector.slick('slickGoTo', $(this).data('slickIndex'));
    }
};

const isBreakpointReached = function() {
    return (prevWidth < MOBILE_BREAKPOINT && $(window).width() >= MOBILE_BREAKPOINT)
        || (prevWidth < TABLET_BREAKPOINT && $(window).width() >= TABLET_BREAKPOINT)
        || (prevWidth >= MOBILE_BREAKPOINT && $(window).width() < MOBILE_BREAKPOINT)
        || (prevWidth >= TABLET_BREAKPOINT && $(window).width() < TABLET_BREAKPOINT);
};

function initCarousel(selector) {
    $(selector).on('init', function (event, slick) {
        $(slick.$slides[0]).addClass('active-img');

        slick.$slides.each((index, el) => {
            $(el).unbind('click');
            $(el).on('click', onSlideClick.bind(el));
        });
    });

    $(selector)
        .slick({
            dots: true,
            arrows: true,
            variableWidth: true,
            infinite: false,
            prevArrow: $('.ex-in-section__arrows-section__left'),
            nextArrow: $('.ex-in-section__arrows-section__right'),
            slidesToShow: 1,
            slidesToScroll: 1,
            cssEase: 'linear',
            swipe: true,
            fade: false,
            responsive: [
                {
                    breakpoint: TABLET_BREAKPOINT,
                    settings: {
                        rows: 0,
                        fade: true,
                        slidesToShow: 1,
                        variableWidth: false,
                    },
                },
                {
                    breakpoint: MOBILE_BREAKPOINT,
                    settings: {
                        rows: 0,
                        fade: false,
                        slidesToShow: 1.1,
                        variableWidth: false,
                    },
                },
            ],
        });

    addInfiniteOnEdge(selector);
    $(selector).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        isDragging = true;
        $(slick.$slides[nextSlide]).addClass('active-img');
        if (nextSlide < currentSlide && nextSlide - 1 !== slick.$slides.length) {
            $(slick.$slides[currentSlide]).removeClass('active-img');
        }
    });
    $(selector).on('afterChange', (event, slick, currentSlide) => {
        isDragging = false;
        slick.$slides.each((i, el) => {
            if (currentSlide < i) {
                $(el).removeClass('active-img');
            }
        });
        $(slick.$slides[currentSlide]).addClass('active-img');
    });
    $(selector).slick('slickUnfilter');
    isDragging = false;
}

initCarousel('#exterior-carousel-wrapper');
$('#interior-carousel').hide();

$(window).resize(
    Foundation.utils.throttle(function (e) {
        let selector;
        if(isBreakpointReached()){
            if (exteriorActive) {
                selector = '#exterior-carousel-wrapper';
            } else {
                selector = '#interior-carousel-wrapper';
            }

            setTimeout(() => {
                initImageLightbox(selector);

                $(selector).find('.slick-slide').each((index, el) => {
                    $(el).unbind('click');
                    $(el).on('click', onSlideClick.bind(el));
                });
                prevWidth = $(window).width();

            }, 1000);

        }
    }, 100)
);
