import $ from 'jquery';

const options = $('.yacht-details-page__header-page--buttons--options');
const button = $('.yacht-details-page__header-page--buttons--btn');
button.mouseenter(() => {
    options.show();
});
const hideOptions = () => {
    options.hide();
};

const fixedButton = $('.yacht-details-page__header-page--buttons__fixed--btn');
const buttonsFixed = $('.yacht-details-page__header-page--buttons__fixed');

if ($(window).width() > 1200) {
    fixedButton.mouseenter(() => {
        options.show();
        buttonsFixed.css('display', 'block');
    });
    buttonsFixed.mouseleave(() => {
        options.hide();
    });
}

buttonsFixed.click((e) => {
    options.toggle();
    $('.locator-open-icon').toggle();
});

const buttons = $('.yacht-details-page__header-page--buttons');
buttons.mouseleave(hideOptions);


$(document).scroll(function () {
    checkOffset('.yacht-details-page__header-page--buttons__fixed');
});

function checkOffset(selector) {
    if (
        $(selector).offset()?.top >= $('.main-footer').offset()?.top
) {
        $(selector).css({
            visibility: 'hidden',
            pointerEvents: 'none'
        });
    }

    if (
        $(selector).offset()?.top < $('.main-footer').offset()?.top
) {
        $(selector).css({
            visibility: 'visible',
            pointerEvents: 'auto',
            position: 'fixed',
            bottom: 32,
            top: '',
        }); // restore when you scroll up
    }
}
