import $ from 'jquery';
import 'slick-carousel';
import {trackPersonalisation} from 'general/js/personalisationTracking';

const roomsCarousel = $('.js-rooms');
const finishCarousel = $('.yacht-details-page__refine-finish--full-image');
const roomTabs = $('.js-room-tab');
const finishes = $('.yacht-details-page__refine-finish--finishes__option');

function fixRoomTabs() {
    let tabsWidth = 0;
    roomTabs.each(function (index, tabItem) {
        tabsWidth += $(tabItem).outerWidth();
    });
    const tabPanelWidth = roomTabs.parent().width();
    if (tabsWidth > tabPanelWidth) {
        roomTabs.parent().addClass('has-overflow');
    } else {
        roomTabs.parent().removeClass('has-overflow');
    }
}

$( document ).ready(function() {
    roomsCarousel.slick({
        dots: false,
        infinite: true,
        arrows: false,
        draggable: false,
        swipe: false,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        useTransform: false,
        waitForAnimate: false
    });

    $(roomTabs[0]).addClass('is-active');

    roomTabs.click(function (e) {
        trackPersonalisation($('.page-layout').attr('data-page-name') + ' Room: ' + $(e.target).text());
        roomTabs.removeClass('is-active');
        $(e.currentTarget).addClass('is-active');
        roomsCarousel.slick('slickGoTo', $(this).index());
    });

    fixRoomTabs();

    finishCarousel.slick({
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: $('.left-arrow'),
        nextArrow: $('.right-arrow'),
        speed: 500,
        fade: true,
        cssEase: 'linear',
        useTransform: false,
        waitForAnimate: false,
        lazyLoad: 'ondemand'
    });

    finishCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        finishes.removeClass('active');
        $(finishes[nextSlide]).addClass('active');
    });

    finishCarousel.on('swipe', function(event, slick) {
        finishCarousel.not(this).slick('slickGoTo', slick.currentSlide);
    });

    finishes.click(function () {
        let $this = $(this);
        trackPersonalisation($('.page-layout').attr('data-page-name') + ' Finish: ' + roomTabs.filter('.is-active').text().trim() + ' - ' + $this.attr('data-finishName'));
        finishCarousel.slick('slickGoTo', $this.index());
    });

    if (window.screen.width <= 1024 && window.screen.width >= 721) {
        let currHtml = $('.yacht-details-page__refine-finish__tablet-heading').html();
        if (currHtml) {
            currHtml = currHtml.toString().replace('<br>', '');
        }
        $('.yacht-details-page__refine-finish__tablet-heading').html(currHtml);
    }
});

$(window).on('resize', () => {
    fixRoomTabs();
});
