import $ from 'jquery';
import 'slick-carousel';
import { addInfiniteOnEdge } from 'general/js/carouselService';
import { sliderForTouchScreens } from 'general/js/carouselService';
import { addArrowHover } from 'general/js/slickArrow';
import {trackPersonalisation} from 'general/js/personalisationTracking';

if (window.innerWidth > 720) {
    const wantedItemsCount = window.innerWidth >= 1600 ? 4 : 3;

    const childrenLength = $('.rooms-section__carousel__item').length;
    if ( childrenLength < wantedItemsCount) {
        for (let i = 0; i < wantedItemsCount - childrenLength; i++) {
            $('.rooms-section__carousel').append('<div class="rooms-section__carousel__item empty-item"></div>');
        }
    }
}

$(window).on('load', () => {
    showHeadingsCarousel();
    showCarousel();

    const $carousel = $('.rooms-section__carousel');
    const $headings = $('.rooms-section__headings');
    const $arrows = $('.rooms-section__carousel__arrows');
    const count = $carousel.find('.slick-slide').length;
    const countHeadings = $headings.find('.slick-slide').length;
    let wantedItems = Math.floor($carousel.slick('slickGetOption', 'slidesToShow'));
    let wantedHeadings = Math.floor($headings.slick('slickGetOption', 'slidesToShow'));
    const lastRoomId = $(".rooms-section__headings__item").last().attr('data-room-id');
    const countLast = $(".rooms-section__carousel__item[data-room-id='" + lastRoomId + "']").length;

    $(window).on('resize', () => {
        wantedItems = Math.floor($carousel.slick('slickGetOption', 'slidesToShow'));
    });

    $('.rooms-section').addClass('is-inited');

    $('.rooms-section__headings__item').on('click', function (el) {
        trackPersonalisation($('.page-layout').attr('data-page-name') + ' Room Images: ' + $(el.target).text());
        if(!$(el.target).hasClass('active-item')) {
            let roomId = $(el.target).attr('data-room-id');
            let currentSlide = getCurrentSlideByRoomId(roomId, 0);

            if((count - currentSlide) < wantedItems || (countHeadings - $(el.target).index()) < wantedHeadings ) {
                iterateTo(currentSlide, $carousel);
                setCurrentItem(currentSlide);
                setActiveItems($(el.target));
            }
        }
    });

    let arrowClick = function() {
        let arrow = this;
        let prevSlide = $carousel.find('.active-current').attr('data-slick-index');
        let currentSlide = prevSlide;

        if ($(arrow).hasClass('ar-right')) {
            currentSlide++;
            if (currentSlide === count || (countLast > 1 && currentSlide > (count - countLast) && (count - currentSlide) < wantedItems)) {
                return;
            }
        }
        if ($(arrow).hasClass('ar-left')) {
            currentSlide--;
            if (currentSlide < 0) {
                return;
            }
        }

        iterateTo(currentSlide, $carousel);
        if((count - currentSlide) < wantedItems) {
            setCurrentItem(currentSlide);

            let $heading = getHeadingByItemIndex(currentSlide);
            if (!$heading.hasClass('active-item')) {
                iterateTo($heading.index(), $('.rooms-section__headings'));
                setActiveItems($heading, currentSlide);
            }
        }
    };
    $arrows.on('click', arrowClick );

    $arrows.each((index, el) => {
        addArrowHover(el);
    });

    function showHeadingsCarousel() {
        const settings = {
            infinite: false,
            centerMode: false,
            dots: false,
            arrows: false,
            slidesToShow: 4.6,
            slidesToScroll: 1,
            focusOnSelect: true,
            rows: 0,
            draggable: true,
            swipe: true,
            swipeToSlide: true,
            useTransform: true,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 4.2,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3.3,
                    },
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2.7,
                    },
                },
                {
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        const $slider = $('.rooms-section__headings');
        $slider.slick(settings);

        $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
            let $heading = $slider.find('.rooms-section__headings__item[data-slick-index='+nextSlide+']');
            let currentItem = getCurrentSlideByRoomId($heading.attr('data-room-id'));
            iterateTo(currentItem, $carousel);
        });

        $slider.on('afterChange', function(event, slick, currentSlide) {
            let $heading = $slider.find('.rooms-section__headings__item.active-item');
            let currentItem = getCurrentSlideByRoomId($heading.attr('data-room-id'));

            if((count - currentItem) < wantedItems || (countHeadings - currentSlide) < wantedHeadings) {
                setCurrentItem(currentItem);
                setActiveItems($heading);
            }
        });

        //sliderForTouchScreens($('.rooms-section__headings'));
        $('.rooms-section__headings__item').eq(0).addClass('active-item');
    }

    function showCarousel() {
        const carouselClass = '.rooms-section__carousel';
        $(carouselClass).is('.slick-initialized') ? $(carouselClass).slick('unslick') : null;
        $(carouselClass)
            .slick({
                infinite: false,
                centerMode: false,
                variableWidth: false,
                dots: false,
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 1,
                focusOnSelect: true,
                prevArrow: $('.ar-left'),
                nextArrow: $('.ar-right'),
                draggable: true,
                swipe: true,
                useTransform: true,
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 1600,
                        settings: {
                            slidesToShow: 3.2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 2.8,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 1000,
                        settings: {
                            slidesToShow: 2.6,
                            slidesToScroll: 1,
                            centerMode: false,
                        },
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 2.1,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 720,
                        settings: {
                            slidesToShow: 2.1,
                            centerPadding: '30px',
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            centerMode: true,
                            centerPadding: '10px',
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });

        $(carouselClass).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $arrows.off('click');

            let $heading = getHeadingByItemIndex(nextSlide);
            iterateTo ($heading.attr('data-slick-index'), $(".rooms-section__headings"));
            setActiveItems($heading, nextSlide);
            iterateTo(nextSlide, $(carouselClass));
            setCurrentItem(nextSlide);
        });

        $(carouselClass).on('afterChange', function(event, slick, currentSlide){
            $arrows.on('click', arrowClick);
        });

        const roomId = $('.rooms-section__headings__item').eq(0).attr('data-room-id');
        setCurrentItem(0);
        setActiveCarouselItems(roomId);
        //sliderForTouchScreens($(carouselClass));
        addInfiniteOnEdge(carouselClass);
    }

    function getHeadingByItemIndex (currentSlide) {
        let roomId = $(".rooms-section__carousel__item").eq(currentSlide).attr('data-room-id');
        let $heading = $(".rooms-section__headings__item[data-room-id='" + roomId + "']");
        return $heading;
    }

    function getCurrentSlideByRoomId(roomId, index) {
        if(index == undefined)
            index = 0;
        let slideIndex = $(".rooms-section__carousel__item[data-room-id='" + roomId + "']").eq(index).closest('.slick-slide').attr('data-slick-index');
        return slideIndex;
    }

    function iterateTo (index, slider) {
        const count = slider.find('.slick-slide').length;
        const wantedItems = Math.floor(slider.slick('slickGetOption', 'slidesToShow'));

        if(count - index > wantedItems) {
            slider.slick('slickGoTo', index);
        }
        else {
            slider.slick('slickGoTo', count - wantedItems);
        }
    }

    function setCurrentItem (itemIndex) {
        $(".rooms-section__carousel .slick-slide.active-current").removeClass('active-current');
        $(".rooms-section__carousel .slick-slide[data-slick-index='" + itemIndex + "']").addClass('active-current');
    }

    function setActiveItems($el) {
        if(!$el.hasClass('active-item')) {
            $('.rooms-section__headings__item').each(function() {
                $(this).removeClass('active-item');
            });
            $el.addClass('active-item');
        }
        const roomId = $el.attr('data-room-id');
        setActiveCarouselItems (roomId);
    }

    function setActiveCarouselItems (roomId) {
        const $items = $(".rooms-section__carousel__item[data-room-id='" + roomId + "']").closest('.slick-slide');

        if(!$items.eq(0).hasClass('active-item')) {
            $(".rooms-section__carousel .slick-slide.active-item").each(function(){
                $(this).removeClass('active-item').removeClass('active-current');
            });

            $items.each(function() {
                $(this).addClass('active-item');
            });
        }
    }
});