import $ from 'jquery';
import '../vendors/jquery.magnific-popup-custom';
import {trackVideo} from 'general/js/videoTrackingService';

function initVideoLightbox() {
    const videolightboxes = $('.js-lightbox-trigger-video');
    if (videolightboxes.length > 0) {
        videolightboxes.magnificPopup({
            type: 'iframe',
            preloader: true,
            fixedContentPos: false,
            removalDelay: 500,
            callbacks: {
                open: function () {
                    $('body').addClass('noscroll');

                    let iframe = document.querySelector('.mfp-iframe');
                    if(iframe.src.includes('vimeo.com')) {
                        let player = new Vimeo.Player(iframe);
                        trackVideo(player);
                    }
                },
                close: function () {
                    $('body').removeClass('noscroll');
                },
            },
            midClick: true,
        });
        videolightboxes.on('click', (e) => {
            e.preventDefault();
        });
    }
}

function initImageLightbox(selector) {
    let imagelightboxes;

    if(selector) {
        imagelightboxes = $(selector).find('.js-lightbox-trigger-image');
    } else {
        imagelightboxes = $('.js-lightbox-trigger-image');
    }

    if (imagelightboxes.length > 0) {
        imagelightboxes.magnificPopup({
            type: 'image',
            preloader: true,
            fixedContentPos: false,
            removalDelay: 500,
            closeMarkup: "<span class='mfp-close'></span>",
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            callbacks: {
                open: function () {
                    $('body').addClass('noscroll');
                },
                close: function () {
                    $('body').removeClass('noscroll');
                },
            },
            midClick: true,
        });
        imagelightboxes.on('click', (e) => {
            e.preventDefault();
        });
    }
}

export { initVideoLightbox };
export { initImageLightbox };