import $ from 'jquery';
import { addArrowHover } from 'general/js/slickArrow';
import 'slick-carousel';
import Accordion from 'legacy/js/components/accordion';
import {trackPersonalisation} from 'general/js/personalisationTracking';

const accordionEl = new Accordion();

const specificationModal = $('.yacht-details-page__specifications-modal');
const specificationModalMobile = $('.yacht-details-page__specifications-modal-mobile');
let isOpen = false;
let prevWidth = $(window).width();
let isMobileOpen, isDesktopOpen = false;

const closeSpecificationsModal = () => {
    specificationModalMobile.fadeOut();
    specificationModal.fadeOut();
    let bodyTopOffset = $('body').offset().top;
    $('body').css('overflow-y', 'scroll').css('position', 'relative').css('top', 'auto');
    window.scrollTo(0, -bodyTopOffset);
    isOpen = false;
    isMobileOpen = false;
    isDesktopOpen = false;
};

const closeButton = $('.yacht-details-page__specifications-modal--close__btn');
const closeButtonMobile = $(
    '.yacht-details-page__specifications-modal__info-box--header-wrapper--close-btn'
);
closeButton.on('click', closeSpecificationsModal);
closeButtonMobile.on('click', closeSpecificationsModal);

const openButton = $('.specification-section__info__button');
openButton.on('click', (e) => {
    e.preventDefault();
    trackPersonalisation($('.page-layout').attr('data-page-name') + ': ' + 'Full specification');
    openSpecificationsModal();
});

const initCarousel = (carousel, options) => {
    carousel.not('.slick-initialized').slick(options);
    carousel.on('init', () => {
        accordionEl.init();
    });
};

// END

const initDimensionsCarousel = (carousel, options, stopBeforeChangePropagation) => {
    if(carousel.find('.yacht-details-page__specifications-modal__info-box--content-carousel__dimensions--list').length < 2) return;
    
    initCarousel(carousel, options);

    const layoutHeadings = $(
        '.yacht-details-page__specifications-modal .yacht-details-page__specifications-modal__info-box--content-carousel__dimensions__headings-list__heading'
    );

    const layoutHeadingsMobile = $(
        '.yacht-details-page__specifications-modal-mobile .yacht-details-page__specifications-modal__info-box--content-carousel__dimensions__headings-list__heading'
    );

    layoutHeadings.off('click');
    layoutHeadings.on('click', function () {
        carousel.slick('slickGoTo', $(this).index());
    });

    layoutHeadingsMobile.off('click');
    layoutHeadingsMobile.on('click', function () {
        carousel.slick('slickGoTo', $(this).index());
    });

    carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        stopBeforeChangePropagation ? event.stopPropagation() : null;
        layoutHeadings.removeClass('active');
        $(layoutHeadings[nextSlide]).addClass('active');
        layoutHeadingsMobile.removeClass('active');
        $(layoutHeadingsMobile[nextSlide]).addClass('active');
    });

    if(layoutHeadings.length)layoutHeadings[0].click();
    if(layoutHeadingsMobile.length)layoutHeadingsMobile[0].click();
};
const dimensionsCarouselOptions = {
    centerMode: true,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    infinite: false,
    swipe: false,
    variableWidth: false,
    centerPadding: '0px',

};
const dimensionsCarouselMobile = $(
    '.yacht-details-page__specifications-modal__info-box--content-carousel--dimensions.mobile'
);
const dimensionsCarouselDesktop = $(
    '.yacht-details-page__specifications-modal__info-box--content-carousel--dimensions'
).not('.mobile');

const desktopCarousel = $('.yacht-details-page__specifications-modal--info-carousel');
const destroyDesktop = () => {
    if (desktopCarousel.hasClass('slick-initialized')) {
        desktopCarousel.slick('unslick');
    }
};

const mobileCarousel = $('.yacht-details-page__specifications-modal__info-box--content-carousel');
const destroyMobile = () => {
    if (mobileCarousel.hasClass('slick-initialized')) {
        mobileCarousel.slick('unslick');
    }
};

initDimensionsCarousel(dimensionsCarouselMobile, dimensionsCarouselOptions, false);
const initializeAll = () => {
    if ($(window).width() < 1024) {
        destroyDesktop();
        const headingsMobile = $(
            '.yacht-details-page__specifications-modal__info-box--headings-carousel__heading.mobile'
        );

        const headingsCarouselMobile = $(
            '.yacht-details-page__specifications-modal__info-box--headings-carousel.mobile'
        );

        const mobileCarouselOptions = {
            dots: false,
            infinite: false,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            centerMode: true,
            arrows: true,
        };
        initCarousel(mobileCarousel, mobileCarouselOptions);
        mobileCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if ($(window).width() < 720) {
                if (nextSlide === 0) {
                    headingsCarouselMobile.css({
                        left: 30,
                    });
                }
                if (nextSlide === 1) {
                    headingsCarouselMobile.css({
                        left: -145,
                    });
                }
                if (nextSlide === 2) {
                    headingsCarouselMobile.css({
                        left: -232,
                    });
                }
            }
            headingsMobile.removeClass('active');
            mobileCarousel.attr('is-nav-active', '');
        });
        mobileCarousel.on('afterChange', function(event, slick) {
            if(mobileCarousel.attr('is-nav-active') == '') {
                mobileCarousel.attr('is-nav-active', slick.currentSlide);
                $(headingsMobile[slick.currentSlide]).addClass('active');
            }
        });
        headingsMobile.on('click', function () {
            mobileCarousel.slick('slickGoTo', $(this).index() - 1);
        });
        $(headingsMobile[0]).click();
        headingsMobile.removeClass('active');
        $(headingsMobile[0]).addClass('active');
        initDimensionsCarousel(dimensionsCarouselMobile, dimensionsCarouselOptions, true);
    } else {
        destroyMobile();
        const desktopCarouselOptions = {
            dots: false,
            infinite: false,
            speed: 500,
            arrows: true,
            useTransform: false,
            accessibility: true,
            prevArrow: $(
                '.yacht-details-page__specifications-modal__info-box--headings-carousel--prev-arrow'
            ),
            nextArrow: $(
                '.yacht-details-page__specifications-modal__info-box--headings-carousel--next-arrow'
            ),
        };
        initCarousel(desktopCarousel, desktopCarouselOptions);
        const headingsDesktop = $(
            '.yacht-details-page__specifications-modal__info-box--headings-carousel__heading'
        ).not('.mobile');
        headingsDesktop.click(function () {
            desktopCarousel.slick('slickGoTo', $(this).index() - 1);
        });
        initDimensionsCarousel(dimensionsCarouselDesktop, dimensionsCarouselOptions, true);
    }
};
const openSpecificationsModal = () => {
    prevWidth = $(window).width();
    let windowScroll = window.scrollY;

    if ($(window).width() < 1024) {
        specificationModalMobile.css('display', 'flex').hide().fadeIn();
        isMobileOpen = true;
    } else {
        specificationModal.fadeIn();
        isDesktopOpen = true;
    }
    initializeAll();

    $('body').css('overflow-y', 'hidden').css('position', 'fixed').css('top', '-' + windowScroll + 'px');
    //IE polyfill for dispatching resize
    if (
        navigator.userAgent.indexOf('MSIE') !== -1 ||
        navigator.appVersion.indexOf('Trident/') > 0
    ) {
        const evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
    } else {
        window.dispatchEvent(new Event('resize'));
    }
    addArrowHover(
        '.yacht-details-page__specifications-modal__info-box--headings-carousel--prev-arrow'
    );
    addArrowHover(
        '.yacht-details-page__specifications-modal__info-box--headings-carousel--next-arrow'
    );
    isOpen = true;
};

$(window).on('resize', () => {
    if ($(window).width() !== prevWidth) {
        if(isMobileOpen && $(window).width() >= 1024) {
            isMobileOpen = false;
            isDesktopOpen = true;
            specificationModalMobile.fadeOut();
            specificationModal.fadeIn();
            initializeAll();
        }
        if(isDesktopOpen && $(window).width() < 1024) {
            isMobileOpen = true;
            isDesktopOpen = false;
            specificationModal.fadeOut();
            specificationModalMobile.css('display', 'flex').hide().fadeIn();
            initializeAll();
        }
    }
    prevWidth = $(window).width();
});

const mobilePrev = $(
    '.yacht-details-page__specifications-modal__info-box--footer-wrapper--arrows-prev'
);
const mobileNext = $(
    '.yacht-details-page__specifications-modal__info-box--footer-wrapper--arrows-next'
);
const carousel = $('.yacht-details-page__specifications-modal__info-box--content-carousel.mobile');
if ($(window).width() < 640) {
    mobilePrev.on('click', () => {
        carousel.slick('slickPrev');
    });
    mobileNext.on('click', () => {
        carousel.slick('slickNext');
    });
}
const tabletPrev = $(
    '.yacht-details-page__specifications-modal__info-box--headings-carousel--prev-arrow'
);
const tabletNext = $(
    '.yacht-details-page__specifications-modal__info-box--headings-carousel--next-arrow'
);
if ($(window).width() >= 640 && $(window).width() < 1024) {
    tabletPrev.on('click', () => {
        carousel.slick('slickPrev');
    });
    tabletNext.on('click', () => {
        carousel.slick('slickNext');
    });
}
