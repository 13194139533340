import $ from 'jquery';

export const addArrowHover = (nativeEl) => {
    const el = $(nativeEl);
    const path = el.children().find('path.line');
    const chevron = el.children().find('path.chevron');
    el.on('touchstart', function (e) {
        path.attr('d', 'M-7,0H24');
        chevron.attr('d', 'M5,-1l7-7,7,7');
    });
    el.on('mouseenter', function (e) {
        path.attr('d', 'M-7,0H24');
        chevron.attr('d', 'M5,-1l7-7,7,7');
        e.preventDefault();
    });
    el.on('touchend', function (e) {
        setTimeout(function(){
            path.attr('d', 'M0,0H24');
            chevron.attr('d', 'M5,7l7-7,7,7');
        }, 500);
    });
    el.on('mouseleave', function (e) {
        path.attr('d', 'M0,0H24');
        chevron.attr('d', 'M5,7l7-7,7,7');
        e.preventDefault();
    });
};

$('.slick-arrow').each((index, el) => {
    addArrowHover(el);
    $(el).parent().css('cursor', 'auto');
    $(el).css('cursor', 'pointer');
});
