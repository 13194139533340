function trackPersonalisation(newExperience) {
    let savedPersonalisationExperience = window.sessionStorage.getItem('experiencePersonalisation');
    let updatedPersonalisationExperience = savedPersonalisationExperience ? savedPersonalisationExperience + ' / ' + newExperience : newExperience;
    window.sessionStorage.setItem('experiencePersonalisation', updatedPersonalisationExperience);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'siteengagement.click',
        'siteengagementType': 'personalisation of experience',
        'siteengagementName': updatedPersonalisationExperience
    });
}

function clearPersonalisation() {
    window.sessionStorage.removeItem('experiencePersonalisation');
}

export { trackPersonalisation, clearPersonalisation };