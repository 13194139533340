import $ from 'jquery';

export default class Accordion {
    constructor() {}

    init() {
        $('.accordion-body').hide();
        $('.accordion-chevron').css({
            transition: 'transform 0.5s',
        });
        $('.accordion-title').off('click');
        $('.accordion-title').on('click', this.clickHandler);
    }

    clickHandler = function () {
        const title = $(this);
        title.siblings().find('*').filter('.accordion-body').slideUp();
        title.parent().siblings().find('*').filter('.accordion-body').slideUp();
        title.parent().siblings().find('*').filter('.accordion-active').removeClass('accordion-active');
        title.siblings().find('*').filter('.accordion-active').removeClass('accordion-active');
        title.toggleClass('accordion-active');
        title.children().filter('.accordion-body').slideToggle();
        title.siblings().filter('.accordion-body').slideToggle();
    }
}