import $ from 'jquery';

function addInfiniteOnEdge(selector) {
    if (!$(selector).hasClass('edge')) {
        $(selector).addClass('edge');
        const prevArrow = $($(selector).slick('getSlick').$prevArrow);
        const nextArrow = $($(selector).slick('getSlick').$nextArrow);
        prevArrow.on('click', function (e) {
            if ($(selector).slick('getSlick').currentSlide === 0) {
                if ($(selector).slick('getSlick').$dots) {
                    $(selector)
                        .slick('getSlick')
                        .$dots.children()
                        [$(selector).slick('getSlick').$dots.children().length - 1].click();
                } else {
                    $(
                        $(selector)
                            .slick('getSlick')
                            .$slider.slick(
                            'slickGoTo',
                            $(selector).slick('getSlick').$slides.length - 1
                        )
                    );
                }
            }
        });
        let prevSlide = $(selector).slick('getSlick').currentSlide;
        nextArrow.on('click', function (e) {
            if (
                $(selector).slick('getSlick').$dots?.children().length - 1 === $(selector).slick('getSlick').currentSlide
            ) {
                $($(selector).slick('getSlick').$slider).slick('slickGoTo', 0);
            }
        });
        $(selector).on('edge', function (event, slick, direction) {
            if (direction === 'right') {
                $(this).slick('slickGoTo', slick.$slides.length - 1);
                //Simulate click on dots
                try {
                    $(slick.$dots.children()[slick.$dots.children().length - 1]).trigger('click');
                } catch (error) {}
            } else {
                $(this).slick('slickGoTo', 0);
            }
        });
    }
}

function sliderForTouchScreens (slider) {
    let xStart, xEnd;
    slider
        .on("mousedown touchstart", function (e) {
            if (e.originalEvent.pageX) {
                xStart = e.originalEvent.pageX;
            } else {
                xStart = e.originalEvent.touches[0].pageX;
            }
        })
        .on("mousemove touchmove", function (e) {
            if (e.originalEvent.pageX) {
                xEnd = e.originalEvent.pageX;
            } else {
                xEnd = e.originalEvent.touches[0].pageX;
            }
        })
        .on("mouseup touchend", function (e) {
            var deltaX = xEnd - xStart;
            if (deltaX < 0) {
                $(this).slick("slickNext");
            }
            if (deltaX > 0) {
                $(this).slick("slickPrev");
            }
        });
}

export { addInfiniteOnEdge };
export { sliderForTouchScreens };