import $ from 'jquery';
import 'slick-carousel';
import 'src/legacy/js/vendors/jquery.magnific-popup-custom';
import 'src/legacy/js/vendors/foundation/foundation';
import {trackPersonalisation} from 'general/js/personalisationTracking';

const MOBILE_BREAKPOINT = 640;
const TABLET_BREAKPOINT = 1024;

export default class Layouts {
    constructor() {
        this.$elements = $('.js-layouts-section');
        this.$tabs = this.$elements.find('.js-layouts-tab');
        this.$images = this.$elements.find('.js-layouts-images');
        this.prevWidth = $(window).width();
        this.init();
    }

    init() {
        $(this.$tabs[0]).addClass('is-active');

        if ($(window).width() < TABLET_BREAKPOINT) {
            this._tabsFix();
            this._lightboxesSrcChange();
        }

        this.imagesCarousel = this.$images
            .slick({
                adaptiveHeight: false,
                arrows: true,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                speed: 600,
                swipe: true,
                cssEase: 'linear',
                infinite: true,
                prevArrow: $('.layouts-section__arrow-left'),
                nextArrow: $('.layouts-section__arrow-right'),
                responsive: [
                    {
                        breakpoint: TABLET_BREAKPOINT,
                        settings: {
                            fade: false,
                            speed: 300
                        }
                    }
                ]
            }).on('afterChange', (e, slick, currentSlide, nextSlide) => {
                this.$tabs.removeClass('is-active');
                $(this.$tabs[currentSlide]).addClass('is-active');
                if ($(window).width() < TABLET_BREAKPOINT) {
                    const offset = $(this.$tabs[currentSlide]).offset().left + this.$tabs.parent().scrollLeft() - (($(window).width()/2) - ($(this.$tabs[currentSlide]).outerWidth()/2));
                    this.$tabs.parent().animate({scrollLeft: offset}, 400);
                }
            });

        this._initPopupGallery();

        this._addListeners();

        $(window).resize(
            Foundation.utils.throttle(() => {
                if ($(window).width() < TABLET_BREAKPOINT) {
                    setTimeout(() => {
                        this._tabsFix();
                        const offset = this.$tabs.filter('.is-active').offset().left + this.$tabs.parent().scrollLeft() - (($(window).width()/2) - (this.$tabs.filter('.is-active').outerWidth()/2));
                        this.$tabs.parent().animate({scrollLeft: offset}, 400);
                    }, 1000);
                }
                if(this._isBreakpointReached()){
                    setTimeout(() => {
                        this._lightboxesSrcChange();
                        this._initPopupGallery();
                        this.prevWidth = $(window).width();
                    }, 1000);
                }
            }, 100)
        );
    }

    _initPopupGallery() {
            const lightbox = $('.js-lightbox-layout');

            if (lightbox.length > 0) {
                lightbox.magnificPopup({
                    type: 'image',
                    preloader: true,
                    fixedContentPos: false,
                    removalDelay: 500,
                    closeMarkup: "<span class='mfp-close'></span>",
                    gallery: {
                        enabled: true,
                        navigateByImgClick: true,
                        preload: [0,1],
                        tCounter: ''
                    },
                    callbacks: {
                        open: function () {
                            $('body').addClass('noscroll');
                        },
                        close: function () {
                            $('body').removeClass('noscroll');
                        },
                    },
                    midClick: true,
                });
                lightbox.on('click', (e) => {
                    e.preventDefault();
                });
            }
    }

    _tabsFix () {
        this.$tabs.removeClass('has-break');
        this.$tabs.each((i) => {
            if($(this.$tabs[i]).width() > ($(window).width()*0.9)) {
                $(this.$tabs[i]).addClass('has-break');
            }
        });
    }

    _lightboxesSrcChange () {
        const lightbox = $('.js-lightbox-layout');

        if (lightbox.length > 0) {
            if ($(window).width() < MOBILE_BREAKPOINT) {
                lightbox.each((index, item) => {
                    $(item).attr('href', $(item).data('mobileSrc'));
                });
            } else if ($(window).width() < TABLET_BREAKPOINT) {
                lightbox.each((index, item) => {
                    $(item).attr('href', $(item).data('tabletSrc'));
                });
            } else {
                lightbox.each((index, item) => {
                    $(item).attr('href', $(item).data('desktopSrc'));
                });
            }
        }
    }

    _isBreakpointReached () {
        return (this.prevWidth < MOBILE_BREAKPOINT && $(window).width() >= MOBILE_BREAKPOINT)
            || (this.prevWidth < TABLET_BREAKPOINT && $(window).width() >= TABLET_BREAKPOINT)
            || (this.prevWidth >= MOBILE_BREAKPOINT && $(window).width() < MOBILE_BREAKPOINT)
            || (this.prevWidth >= TABLET_BREAKPOINT && $(window).width() < TABLET_BREAKPOINT);
    };

    _addListeners() {
        this.$tabs.on('click', this._onTabClick.bind(this));
    }

    _onTabClick(e) {
        trackPersonalisation($('.page-layout').attr('data-page-name') + ' Layouts: ' +  $(e.target).text().trim());
        this.imagesCarousel.slick('slickGoTo', $(e.target).index());
    }
}

